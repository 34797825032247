import { validatePassword } from '../validators/Login';

export function validateNewPassword(value, errorSetter, newPasswordOne, errorElement="newpasswordoneError") {
    let error = validatePassword(value, errorSetter, errorElement);

    if (error) {
        errorSetter({
            [errorElement]: error
        });
    }
    else if (value !== newPasswordOne) {
        errorSetter({
            [errorElement]: `Las contraseñas no coinciden`
        });
    }
    else {
        errorSetter({
            [errorElement]: ""
        });
    }
}