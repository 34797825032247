import { Preloader } from 'react-materialize';

import '../styles/Spinner.css';

export function Spinner(props) {
    return (
        <div>
            {
                props.isLoading &&
                <div className="preloader-container">
                    <Preloader
                        active={props.isLoading}
                        color="blue"
                        flashing={false}
                        size="big"
                        className="generic-preloader"
                    />
                    <p className="preloader-text">Espere un momento por favor...</p>
                </div>
            }
        </div>
    );
}