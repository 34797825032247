import React from 'react';

import {GenericCard} from '../components/Generic';
import {getHomeData} from '../apis/endpoints';

import '../styles/Home.css';
import '../styles/Generic.css';

function HomeModalBody(props) {
    return (
        <>
            <table className="modalTable striped">
                <tbody>
                {
                    props.data.map((item,index)=>{
                        return <tr key={"home-mb-tr-" + index}>
                                    <td key={index}>
                                        {item.subItemContratoTitulo}
                                    </td>
                                    <td key={"home-mb-td-" + index}>
                                        {item.subItemContratoValor}
                                    </td>
                                </tr>
                    })
                }
                </tbody>
            </table>
        </>
    );
}


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contracts: [],
            news: '',
            isLoading: false
        }
    }

    componentDidMount() {
        getHomeData(
            (value, callback) => {
                this.setState(value, callback);
            },
            this.props.updateParentState,
            this.props.history
        )
    }

    render() {
        document.body.style.overflow = "visible";
        return (
            <div className="generic-logged-in-page">
                {
                    this.state.news &&
                    <div className="home-anuncios-container">
                        <div className="home-anuncios-content">
                            <div className="home-anuncios-novedades-titulo">
                                Novedades
                            </div>

                            <div className="home-anuncios-novedades-cuerpo">
                                {this.state.news}
                            </div>
                        </div>
                    </div>
                }
                <div className="home-contratos">
                    <div className="home-contratos-titulo">
                        <h1>Mis Contratos</h1>
                    </div>
                    { this.state.contracts.length > 0 ?
                        <div className="home-contratos-cuerpo">
                            {this.state.contracts.map((item,index)=>{
                                return <GenericCard
                                            cardTitle={item.nombreContrato}
                                            modalBody={<HomeModalBody data={item.detalleContrato} />}
                                            cardOnClick=""
                                            cardKey={index}
                                            modalLocation="home"
                                            modalHref={"#contratosModal" + item.nombreContrato}
                                        />
                             })}
                        </div>
                        :
                        <div className="home-contratos-cuerpo">
                            <p className="error-message">No hay contratos para mostrar.</p>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Home;
