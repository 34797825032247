export function validateDNI(value, errorSetter) {
    let errorMessage = "";

    let isDNINumeric = String(value)
        .toLowerCase()
        .match(
            /^[0-9]+$/g
        );

    if (!isDNINumeric) {
        errorMessage = "El DNI solo debe ser númerico";
    }
    else if (value.length < 7) {
        errorMessage = "El DNI ingresado es demasiado corto";
    }
    else {
        errorMessage = "";
    }

    errorSetter({
        'dniError': errorMessage
    });
}

export function validateAdminCSVFilename(value) {
    let errorMessage = "";

    if (value.length < 2) {
        errorMessage = "El nombre de archivo ingresado es muy corto";
    }
    else if (!value.includes(".csv")) {
        errorMessage = "El archivo seleccionado no tiene formato CSV";
    }

    return errorMessage;
}

export function validateNews(value, errorSetter) {
    let errorMessage = "";
    const maximumNewsLength = 300;

    if (value.length < 2) {
        errorMessage = "La novedad es demasiado corta.";
    }
    else if (value.length > maximumNewsLength) {
        errorMessage = `La novedad debe contener menos de ${maximumNewsLength} caracteres.`;
    }

    errorSetter({
        'latestNewsError': errorMessage
    });
}