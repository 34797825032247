import React from 'react';

import { GenericCard, Line } from '../components/Generic';
import { Checkbox } from 'react-materialize';
import { getScoresData } from '../apis/endpoints';

import M from 'materialize-css';


import '../styles/Puntos.css';
import '../styles/Generic.css';


function PuntosModalBody(props) {
    return (
        <>
            <table className="modalTable puntos-modal-table striped">
                <tbody>
                    {
                        props && props.data ?
                        props.data["detallePuntosContrato"].map((item,index)=>{
                            return <tr key={"puntosmodal-" + index}>
                                        <td key={"puntosmodal-td-b-" + index}>
                                            {item["subItemContratoTitulo"]}
                                        </td>
                                        <td key={"puntosmodal-td--" + index}>
                                            {item["subItemContratoValor"]}
                                        </td>
                                    </tr>
                         })
                         :
                         <></>
                    }
                </tbody>
            </table>
            <Line width="50%"/>
            <table className="modalTable puntos-modal-table striped">
                <tbody>
                    <tr key="total">
                        <td key="total-td-a">
                            Total
                        </td>
                        <td key="total-td-b">
                            { props.data.puntosTotalContrato }
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

class Puntos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            "filterSelection": false,
            "filterNeighbourhood": "Todos",
            "scores": {
                "misPuntos": [],
                "todosLosPuntos": [],
            },
            "filteredTodosLosPuntos": [],
            "item": {
                "nombreContrato": "",
                "puntosTotalContrato": "",
                "detallePuntosContrato": []
            }
        }
    }

    handlePointsOnclickDetail(event, item) {
        this.setState({
            item: item
        });
    }

    componentDidMount() {
        getScoresData(
            (value, callback) => {
                this.setState(value, callback);
            },
            this.props.updateParentState,
            this.props.history
        )

        const options = {
            hover: true
        };
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, options);
    }

    handleFilterSelection(event, shouldPreventDefault) {
        let filteredPoints = this.state.scores["todosLosPuntos"];
        let filterSelection = this.state["filterSelection"];
        let filterNeighbourhood = this.state["filterNeighbourhood"];

        if(event && shouldPreventDefault) {
            event.preventDefault();
        }

        // Filter by "puede adjudicar"
        let isFilterChecked = event.target.checked;

        if (isFilterChecked !== undefined) {
            filterSelection = isFilterChecked;
        }
        if (filterSelection) {
            filteredPoints = filteredPoints.filter(
                item => item["puedeAdjudicar"]
            );
        }

        let selectedOption = event.target.outerText;

        if (selectedOption !== undefined && selectedOption !== "") {
            filterNeighbourhood = selectedOption;
        }
        if (filterNeighbourhood !== 'Todos') {
            filteredPoints = filteredPoints.filter(
                item => item["barrio"] === filterNeighbourhood
            );
        }

        this.setState({
            ...this.state,
            "filteredTodosLosPuntos": filteredPoints,
            "filterSelection": filterSelection,
            "filterNeighbourhood": filterNeighbourhood
        });
    }

    render() {
        document.body.style.overflow = "visible";
        return <>
            <div className="generic-logged-in-page">
                <div className="puntos-titulo">
                    <h1>Mis Puntos</h1>
                </div>
                <div className="puntos-cuerpo">
                    {
                        this.state.scores["misPuntos"].map((item,index)=>{
                            return <GenericCard
                                        cardTitle={item.nombreContrato + ": " + item.puntosTotalContrato + " puntos"}
                                        modalBody={
                                            <PuntosModalBody
                                                data={item}
                                            />
                                        }
                                        cardOnClick=""
                                        cardKey={index}
                                        modalLocation="puntos"
                                        modalHref={"MisPuntos" + item.nombreContrato}
                                    />
                         })
                     }
                </div>

                <div>
                    <div className="puntos-titulo">
                        <h1>Todos los contratos</h1>
                    </div>
                    <div className="puntos-todos-los-contratos-filter-box-container">
                        <div className="puntos-todos-los-contratos-filter-box">
                            {
                                <Checkbox
                                    key="filter_checkbox"
                                    checked={this.state.filterSelection}
                                    value="test"
                                    filledIn
                                    id="CheckboxPuntosFilter"
                                    label="Puede participar del proceso de adjudicación"
                                    className="checkbox-puntos-filter"
                                    onChange={(event) => this.handleFilterSelection(event, false)}
                                />
                            }
                        </div>
                        <div className="puntos-todos-los-contratos-filter-box">
                            <a
                                className='dropdown-trigger btn-flat puntos-todos-los-contratos-filter-neighbourhood'
                                href='/#'
                                data-target='dropdown-barrio'>
                                    Filtrar por barrio
                            </a>

                            <ul id='dropdown-barrio' className='dropdown-content'>
                                <li><a href="/#" onClick={(event) => this.handleFilterSelection(event, true)} className="orange-text">Todos</a></li>
                                <li><a href="/#" onClick={(event) => this.handleFilterSelection(event, true)} className="orange-text">RT1</a></li>
                                <li><a href="/#" onClick={(event) => this.handleFilterSelection(event, true)} className="orange-text">RT2</a></li>
                                <li><a href="/#" onClick={(event) => this.handleFilterSelection(event, true)} className="orange-text">RT3</a></li>
                            </ul>
                        </div>
                    </div>
                    { this.state["filteredTodosLosPuntos"].length > 0 &&
                    <div className="todos-los-puntos-container">
                        <table className="modalTable striped">
                            <tbody>
                                <tr key="puntos-total-tr" className="text-bold">
                                    <td key="total">
                                        Contrato
                                    </td>
                                    <td key="puntos-total-td">
                                        Puntaje
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Line width="50%"/>
                        <table className="modalTable striped">
                        <tbody>
                        {
                            this.state["filteredTodosLosPuntos"].map((item,index)=>{
                                return <tr key={"todos-los-puntos-tr" + index}>
                                            <td key={"todos-los-puntos-td-a-" + index}>
                                                { item.nombreContrato }
                                            </td>
                                            <td
                                                key={"todos-los-puntos-td-b-" + index}
                                            >
                                                { item.puntosTotalContrato }
                                            </td>
                                        </tr>
                            })
                        }
                        </tbody>
                        </table>
                    </div>
                }
                { this.state["filteredTodosLosPuntos"].length === 0 &&
                    <div className="home-contratos-cuerpo">
                        <p className="error-message">No hay resultados que mostrar. Podés intentar remover algunos filtros.</p>
                    </div>
                }
                </div>
            </div>
        </>;
    }
}

export default Puntos;
