const validateEmailHelper = (email) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function validateEmail(value, errorSetter) {
    if (!value.includes("@")) {
        errorSetter({
            "emailError": "Debe incluir @"
        });
    }
    else if (!validateEmailHelper(value)) {
        errorSetter({
            "emailError": "El e-mail ingresado no es válido"
        });
    }
    else {
        errorSetter({"emailError": ""});
    }
}

export function validatePassword(value, errorSetter, errorElement="passwordError") {
    const minPassLength = 6;

    let error;
    if (value.length < minPassLength) {
        error = `Debe contener al menos ${minPassLength} caracteres`;
    }
    else {
        error = "";
    }

    errorSetter({
        [errorElement]: error
    });

    return error;
}