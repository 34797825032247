import React from 'react';

import { GenericFormSubmitButton, GenericFormElement } from '../components/Generic';
import { validateEmail } from '../validators/Login';
import { forgotPassword } from '../apis/endpoints';
import { Spinner } from '../components/Spinner';

import logo from '../assests/logo.png';

import '../styles/Login.css';


let validatorsMapping = {
    "email": validateEmail
};


class ForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: '',
            emailError: '',
        }
    }

    isButtonDisabled() {
        return (this.state.email === '' || this.state.password ==='')
            || (this.state.email !== ''
                && this.state.emailError !== '');
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    handleUserInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
        validatorsMapping[name](
            value,
            this.updateState
        );
    }

    handleForgotPassword(event) {
        forgotPassword(
            this.state.email,
            (data, callback) => {
                this.setState(data, callback);
            },
            this.props.updateState
        );
        event.preventDefault();
    }

    render() {
        return (
            <div className="login">
                <Spinner isLoading={this.state.isLoading}/>
                <form className="loginForm" onSubmit={this.handleForgotPassword.bind(this)}>
                    <img
                        src={logo}
                        className="mainLogo"
                        alt="Reserva Tajamar"
                    />
                    { this.state.errorMessage &&
                    <>
                        <GenericFormElement
                            inputName="E-mail"
                            inputValue={this.state.email}
                            elementId="email"
                            onChange={(event) => this.handleUserInput(event)}
                            error={this.state.emailError}
                            isPassword={false}
                            disabled={true}
                        />
                        <p className="success-message">{this.state.errorMessage}</p>
                        <a className="btn blue darken-4" href="/">
                            Continuar
                        </a>
                    </>
                    }
                    { !this.state.errorMessage &&
                    <>
                        <GenericFormElement
                            inputName="E-mail"
                            inputValue={this.state.email}
                            elementId="email"
                            onChange={(event) => this.handleUserInput(event)}
                            error={this.state.emailError}
                            isPassword={false}
                        />
                        <GenericFormSubmitButton
                            buttonName="Recuperar cuenta"
                            buttonColorClasses="blue darken-4"
                            isButtonDisabled={this.isButtonDisabled()}
                        />
                    </>
                    }
                </form>
            </div>
        );
    }
}

export default ForgotPasswordForm;
