import React from 'react';
import {
    BrowserRouter,
    Link,
    Route,
    Routes,
    Navigate
} from "react-router-dom";
import {Icon} from 'react-materialize';
import { createBrowserHistory } from "history";

import '../styles/Menu.css';

import logo from '../assests/logo.png';

// Import materialize-css related files
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css/dist/js/materialize';
import 'material-icons/iconfont/material-icons.css';

import Contacto from './Contacto';
import Clave from './Clave';
import Home from './Home';
import Logout from './Logout';
import Puntos from './Puntos';


const history = createBrowserHistory();


class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuClass: 'menu',
            menuContainerClass: 'menuContainer'
        }
    }

    handleMenuClick(event) {
        this.setState({
            'menuClass': this.state.menuClass === 'menu' ? 'menu menu-unhidden' : 'menu',
            'menuContainerClass': this.state.menuContainerClass === 'menuContainer' ? 'menuContainer-unhidden': 'menuContainer'
        });
    }

    updateState = (data) => {
        this.setState(data);
    }

    render() {
        return (
            <BrowserRouter history={history}>
                <div className={this.state.menuContainerClass}>
                    <div className="menu-logo-container" onClick={ (event) => this.handleMenuClick(event)}>
                        <Icon className="menu-icon">menu</Icon>
                        <img
                            src={logo}
                            className="menu-logo"
                            alt="Reserva Tajamar"
                        />
                    </div>
                    <ul id="menu" className={this.state.menuClass}>
                        <li>
                            <Icon className="menu-icon">home</Icon>
                            <Link className="menu-option-link" to="/">Inicio</Link>
                        </li>
                        <li>
                            <Icon className="menu-icon">star</Icon>
                            <Link className="menu-option-link" to="/puntos">Puntos</Link>
                        </li>
                        <li>
                            <Icon className="menu-icon">email</Icon>
                            <Link className="menu-option-link" to="/contacto">Contacto</Link>
                        </li>
                        <li>
                            <Icon className="menu-icon">person</Icon>
                            <Link className="menu-option-link" to="/clave">Contraseña</Link>
                        </li>
                        <li>
                            <Icon className="menu-icon">directions_run</Icon>
                            <Link className="menu-option-link" to="/salir">Salir</Link>
                        </li>
                    </ul>
                </div>
                <Routes>
                    <Route path='/' element={<Home key={Date.now()} updateParentState={this.updateState} history={history}/>}/>
                    <Route path='/contacto' element={<Contacto history={history} updateParentState={this.updateState}/>}/>
                    <Route path='/puntos' element={<Puntos key={Date.now()} history={history} updateParentState={this.updateState}/>}/>
                    <Route path='/clave' element={<Clave key={Date.now()} history={history} updateParentState={this.updateState}/>}/>
                    <Route path='/salir' element={<Logout updateState={this.props.updateState} history={history}/>}/>
                    <Route path='*' element={<Navigate to="/" replace />}></Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default Menu;
