import React from 'react';

import Admin from '../components/Admin';
import LoginManager from '../components/Login';
import Menu from '../components/Menu';
import { LOCAL_STORAGE_AUTH_TOKEN, getIsUserAdmin } from '../apis/endpoints';

import '../styles/IndexApp.css';


class IndexApp extends React.Component {
    constructor(props) {
        super(props);
        let accessToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
        let isUserLoggedin = accessToken !== null;

        this.state = {
            isUserLoggedin: isUserLoggedin,
            isUserAdmin: false
        }
        this.accessToken = accessToken;
    }

    updateState = (data) => {
        this.setState(data);
    }

    componentDidMount() {
        if (this.accessToken) {
            getIsUserAdmin(this.accessToken, this.updateState);
        }
    }

    render() {
        return (
            <div className="App">
                {
                    !this.state.isUserLoggedin &&
                    <LoginManager
                        updateState={this.updateState}
                        setisUserAdmin={this.setisUserAdmin}
                        setState={this.setState}
                    />
                }
                {
                    this.state.isUserLoggedin &&
                    this.state.isUserAdmin && <Admin updateParentState={this.updateState}/>
                }
                {
                    this.state.isUserLoggedin &&
                    !this.state.isUserAdmin &&
                    <Menu updateState={this.updateState}/>
                }
            </div>
        );
    }
}

export default IndexApp;
