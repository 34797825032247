let BACKEND_SERVER = `${process.env.REACT_APP_BACKEND_URL}api`;
let LOCAL_STORAGE_AUTH_TOKEN = "9284cm8xpokeolksdlfk";
let LOCAL_STORAGE_AUTH_TOKEN_REFRESH = "92p4cm8xpokeplksdlfk";
let GENERIC_ERROR_MESSAGE = "La operación no fue exitosa, por favor intente de nuevo más tarde.";
let FAILED_LOG_IN_ERROR = "La combinación de usuario y contraseña ingresada no es correcta.";
let FAILED_CONTACT_ERROR = "No se pudo enviar el formulario en este momento, por favor intente más tarde.";
let OK_CONTACT_MESSAGE = "Hemos recibido su mensaje. ¡Gracias por contactarnos!";
let FAILED_CHANGE_PASSWORD_ERROR = "No se pudo cambiar su contraseña en este momento, por favor intente más tarde.";
let OK_CHANGE_PASSWORD_MESSAGE = "Su contraseña ha sido modificada exitosamente.";
let UNAUTHORIZED_ERROR = 401;
let ADD_CLIENT_OK_MSG = "La creación del nuevo usuario fue exitosa.";
let ADD_NEWS_OK_MSG = "La novedad fue añadida exitosamente.";
let DELETE_CLIENT_OK_MSG = "El usuario ha sido eliminado exitosamente.";
let MODIFY_CLIENT_OK_MSG = "El usuario ha sido modificado con éxito.";
let UPLOAD_OK_MSG = "El archivo ha sido procesado con éxito.";
let FORGOT_PASSWORD_MSG = "Si los datos ingresados son correctos, recibirá un e-mail con su nueva contraseña.";


export function clearLocalStorage(updateState, history, err) {
    localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, null);
    localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_REFRESH, null);
    localStorage.clear();
    history.push('/');
    updateState({
        isUserLoggedin: false
    });
}

async function handleError(
    err,
    updateState,
    updateParentState,
    history,
    returnValue,
    errorFromFE=GENERIC_ERROR_MESSAGE,
    shouldRetry=true,
    functionToRetry=null
) {
    let errorFromBE = await err.json();
    let errMsg = errorFromBE && errorFromBE["error_text"] ? errorFromBE["error_text"] : errorFromFE;

    if ((err.status === UNAUTHORIZED_ERROR) && shouldRetry) {
        errMsg = "";
        extendToken(updateState, updateParentState, history, functionToRetry);
    }

    updateState({
        isLoading: false,
        errorMessage: errMsg
    });

    return returnValue;
}

function handleAuthTokens(response) {
    let access_token = response["access"];
    let refresh_token = response["refresh"];

    if (access_token) {
        localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, access_token);
    }

    if (refresh_token) {
        localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_REFRESH, refresh_token);
    }
}

function handleResponse(response, updateState, generateExtraPayload, updateParentState=null, generateExtraParentPayload=null) {
    updateState({
        ...generateExtraPayload(),
        isLoading: false,
    });

    handleAuthTokens(response);

    if (updateParentState && generateExtraParentPayload()) {
        updateParentState({
            ...generateExtraParentPayload()
        });
    }

    return response;
}

export function forgotPassword(email, updateState, updateParentState) {
    let data = {
        email: email
    };

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/user/password/reset/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                updateParentState({
                    isUserLoggedin: false,
                    isLoading: false,
                    errorMessage: FORGOT_PASSWORD_MSG
                });

                return {
                    isUserLoggedIn: false,
                    isLoading: false,
                    errorMessage: FORGOT_PASSWORD_MSG
                };
            }
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            null, // history
            [],
            FORGOT_PASSWORD_MSG,
            false,
            null))
    });
}

export function logInUser(username, password, updateState, updateParentState) {
    let data = {
        username: username,
        password: password
    };

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/token/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                updateParentState({
                    isUserLoggedin: true
                });

                getIsUserAdmin(response["access"], updateParentState);

                return { isUserLoggedIn: true };
            }
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            null, // history
            [],
            FAILED_LOG_IN_ERROR,
            false,
            null))
    });
}

export function extendToken(updateState, updateParentState, history, functionToRetry) {
    let data = {
        'refresh': localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_REFRESH)
    };

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/token/refresh/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                updateState({
                    isLoading: false,
                });

                if (functionToRetry) {
                    functionToRetry(null);
                }
                // Lo único que falta es recargar la página desde la cual fue experirado el token
                // El problema es que no está haciendo mount, solo está haciendo rendering
                return { isUserLoggedIn: true };
            }
        ))
        .catch(err => clearLocalStorage(updateState, history, err))
    });
}

export function getIsUserAdmin(authToken, updateState) {
    fetch(
        `${BACKEND_SERVER}/is_admin`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        }
    )
    .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
    .then(response => handleResponse(
            response,
            updateState,
            () => {
                return { isUserAdmin: response["is_admin"] };
            }
        )
    )
    .catch(err => handleError(
        err,
        updateState,
        null,  // updateParentState
        null,  // history
        [],
        null,  // errorMessage
        false,
        null)
    )
}

export function getHomeData(updateState, updateParentState, history) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(
            `${BACKEND_SERVER}/home`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            }
        )
        .then(response => {
                if(!response.ok) throw response;
                else return response.json();
            })
        .then(response => handleResponse(
                response,
                updateState,
                () => {
                    return {
                        contracts: response["home"],
                        news: response["news"]
                    };
                }
            )
        )
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            null,
            true,
            null)
        )
    })
}

export function getScoresData(updateState, updateParentState, history) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(
            `${BACKEND_SERVER}/scores`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            }
        )
        .then(response => {
                if(!response.ok) throw response;
                else return response.json();
            })
        .then(response => handleResponse(
                response,
                updateState,
                () => {
                    return {
                        filterSelection: false,
                        scores: response,
                        filteredTodosLosPuntos: response["todosLosPuntos"]
                    };
                }
            )
        )
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            null,
            true,
            null)
        )
    })
}

export function sendContactData(data, updateState, updateParentState, history, functionToRetry) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/contact/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                return { successMessage: OK_CONTACT_MESSAGE };
            }
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            FAILED_CONTACT_ERROR,
            true,
            functionToRetry)
        )
    });
}

export function getAdminClients(updateState, updateParentState, history) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(
            `${BACKEND_SERVER}/admin_clients`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            }
        )
        .then(response => {
                if(!response.ok) throw response;
                else return response.json();
            })
        .then(response => handleResponse(
                response,
                updateState,
                () => {
                    return { client_data: response };
                }
            )
        )
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            null,
            true,
            null)
        )
    })
}

export function addNewClientAsAdmin(data, updateState, updateParentState, history, functionToRetry) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/add_client/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                return {
                    successMessage: ADD_CLIENT_OK_MSG,
                    namesurname: "",
                    email: "",
                    phone: "",
                    dni: ""
                };
            },
            updateParentState,
            () => {
                return {
                    should_force_update: Date.now()
                };
            },
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            GENERIC_ERROR_MESSAGE,
            true,
            functionToRetry)
        )
    });
}

export function addNewsAsAdmin(data, updateState, updateParentState, history, functionToRetry) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/add_news/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                return {
                    successMessage: ADD_NEWS_OK_MSG,
                    namesurname: "",
                    email: "",
                    phone: "",
                    dni: ""
                };
            },
            updateParentState,
            () => {
                return {
                    should_force_update: Date.now()
                };
            },
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            GENERIC_ERROR_MESSAGE,
            true,
            functionToRetry)
        )
    });
}

export function deleteClientAsAdmin(data, updateState, updateParentState, history, functionToRetry) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/delete_client/`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                return {
                    successMessage: DELETE_CLIENT_OK_MSG,
                    namesurname: "",
                    email: "",
                    phone: "",
                    dni: ""
                };
            },
            updateParentState,
            () => {
                return {
                    should_force_update: Date.now()
                };
            },
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            GENERIC_ERROR_MESSAGE,
            true,
            functionToRetry)
        )
    });
}

export function updateClientAsAdmin(data, updateState, updateParentState, history, functionToRetry) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/update_client/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                return {
                    successMessage: MODIFY_CLIENT_OK_MSG,
                    namesurname: "",
                    email: "",
                    phone: "",
                    dni: ""
                };
            },
            updateParentState,
            () => {
                return {
                    should_force_update: Date.now()
                };
            },
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            GENERIC_ERROR_MESSAGE,
            true,
            functionToRetry)
        )
    });
}

export function uploadFileAsAdmin(file, updateState, updateParentState, history, functionToRetry) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/upload/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Disposition': `attachment; filename=file.csv`,
                'Authorization': `Bearer ${authToken}`
            },
            body: file
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                return {
                    successMessage: UPLOAD_OK_MSG,
                    namesurname: "",
                    email: "",
                    phone: "",
                    dni: ""
                };
            },
            updateParentState,
            () => {
                return {
                    should_force_update: Date.now()
                };
            },
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            GENERIC_ERROR_MESSAGE,
            true,
            functionToRetry)
        )
    });
}

export function sendChangePasswordRequest(data, updateState, updateParentState, history, functionToRetry) {
    let authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);

    return updateState({ isLoading: true }, () => {
        fetch(`${BACKEND_SERVER}/user/password/update/`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if(!response.ok) throw response;
            else return response.json();
        })
        .then(response => handleResponse(
            response,
            updateState,
            () => {
                return { successMessage: OK_CHANGE_PASSWORD_MESSAGE };
            }
        ))
        .catch(err => handleError(
            err,
            updateState,
            updateParentState,
            history,
            [],
            FAILED_CHANGE_PASSWORD_ERROR,
            true,
            functionToRetry)
        )
    });
}

export { LOCAL_STORAGE_AUTH_TOKEN };
export { LOCAL_STORAGE_AUTH_TOKEN_REFRESH };