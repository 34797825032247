import React from 'react';


import { clearLocalStorage } from '../apis/endpoints';

class Logout extends React.Component {
    componentDidMount() {
        clearLocalStorage(this.props.updateState, this.props.history)
    }

    render() {
        return (
            <div>
                Logging out
            </div>
        );
    }
}

export default Logout;