import M from 'materialize-css'
import { Icon, Button, Table } from 'react-materialize';
import React from 'react';
import logo from '../assests/logo.png';
import { validateEmail } from '../validators/Login';
import { validateNameSurname, validatePhone } from '../validators/Contact';
import { validateDNI } from '../validators/Admin';
import { clearLocalStorage, getAdminClients, addNewsAsAdmin, addNewClientAsAdmin, deleteClientAsAdmin, updateClientAsAdmin, uploadFileAsAdmin } from '../apis/endpoints';
import { createBrowserHistory } from "history";
import { Spinner } from '../components/Spinner';

import '../styles/Admin.css';
import 'materialize-css/dist/js/materialize';
import { GenericFormSubmitButton, GenericFormElement, GenericModal } from '../components/Generic';
import { validateAdminCSVFilename, validateNews } from '../validators/Admin';

let validatorsMapping = {
    "namesurname": validateNameSurname,
    "email": validateEmail,
    "phone": validatePhone,
    "dni": validateDNI,
    "latestNews": validateNews
};

export class AddNewsModalBody extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: "",
            successMessage: "",
            latestNews: ""
        };
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    addNewsClient(event) {
        let data = {
            'txt': this.state.latestNews
        }
        addNewsAsAdmin(
            data,
            this.updateState,
            this.props.updateParentState,
            this.props.history,
            this.addNewsAsAdmin
        );

        if (event) {
            event.preventDefault();
        }
    }

    handleUserInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
        validatorsMapping[name](
            value,
            this.updateState
        );

        if ( this.state.errorMessage !== "" ) {
            this.setState({
                errorMessage: ""
            });
        }

        if ( this.state.successMessage !== "" ) {
            this.setState({
                successMessage: ""
            });
        }

        event.preventDefault();
    }

    isButtonDisabled() {
        return (this.state.latestNews !== "" && this.state.latestNewsError !== "");
    }

    render() {
        return (
        <>
            <form className="adminAddNewClientForm" onSubmit={(event) => this.addNewsClient(event)}>
                <GenericFormElement
                    inputName="Nueva novedad"
                    inputValue={this.state.latestNews}
                    onChange={(event) => this.handleUserInput(event)}
                    error={this.state.latestNewsError}
                    elementId="latestNews"
                    isPassword={false}
                />
                { this.state.successMessage &&
                    <p className="success-message">{this.state.successMessage}</p>
                }
                { this.state.errorMessage &&
                    <p className="error-message">{this.state.errorMessage}</p>
                }
                <GenericFormSubmitButton
                    buttonName={this.props.buttonName}
                    buttonColorClasses="orange"
                    isButtonDisabled={this.isButtonDisabled()}
                />
            </form>
        </>
    );
    }
}

export class AddNewClientModalBody extends React.Component {
    constructor(props) {
        super(props);

        let user_id = null;
        let namesurname = "";
        let email = "";
        let phone = "";
        let dni = "";


        if (this.props.item) {
            user_id=this.props.item.user_id;
            namesurname=this.props.item.full_name;
            email=this.props.item.email;
            phone=this.props.item.phone;
            dni=this.props.item.dni;
        }

        this.state = {
            errorMessage: "",
            successMessage: "",
            namesurname: namesurname,
            namesurnameError: "",
            email: email,
            emailError: "",
            phone: phone,
            phoneError: "",
            dni: dni,
            dniError: "",
            user_id: user_id
        };
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    componentDidMount() {
        if (this.props.item) {
            this.setState({
                user_id: this.props.item.user_id,
                namesurname: this.props.item.full_name,
                email: this.props.item.email,
                phone: this.props.item.phone,
                dni: this.props.item.dni
            });
        }
    }

    addOrModifyClient(event) {
        let data;
        if (this.props.isNewClient) {
            data = {
                'full_name': this.state.namesurname,
                'email': this.state.email,
                'phone': this.state.phone,
                'dni': this.state.dni
            }
            addNewClientAsAdmin(
                data,
                this.updateState,
                this.props.updateParentState,
                this.props.history,
                this.addOrModifyClient
            );
        }
        else {
            data = {
                'user_id': this.state.user_id,
                'full_name': this.state.namesurname,
                'email': this.state.email,
                'phone': this.state.phone,
                'dni': this.state.dni
            }
            updateClientAsAdmin(
                data,
                this.updateState,
                this.props.updateParentState,
                this.props.history,
                this.addOrModifyClient
            );
        }

        if (event) {
            event.preventDefault();
        }
    }

    handleUserInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
        validatorsMapping[name](
            value,
            this.updateState
        );

        if ( this.state.errorMessage !== "" ) {
            this.setState({
                errorMessage: ""
            });
        }

        if ( this.state.successMessage !== "" ) {
            this.setState({
                successMessage: ""
            });
        }

        event.preventDefault();
    }

    isButtonDisabled() {
        return (this.state.namesurnameError !== "" && this.state.namesurname !== "")
            || (this.state.emailError !== "" && this.state.email !== "")
            || (this.state.dniError !== "" && this.state.dni !== "")
            || (this.state.phoneError !== "" && this.state.phone !== "")
            || this.state.namesurname === ""
            || this.state.email === ""
            || this.state.dni === ""
            || this.state.phone === "";
    }

    render() {
        return (
        <>
            <form className="adminAddNewClientForm" onSubmit={(event) => this.addOrModifyClient(event)}>
                <GenericFormElement
                    inputName="Nombre y apellido *"
                    inputValue={this.state.namesurname}
                    onChange={(event) => this.handleUserInput(event)}
                    error={this.state.namesurnameError}
                    elementId="namesurname"
                    isPassword={false}
                />
                <GenericFormElement
                    inputName="E-mail *"
                    inputValue={this.state.email}
                    onChange={(event) => this.handleUserInput(event)}
                    error={this.state.emailError}
                    elementId="email"
                    isPassword={false}
                />
                <GenericFormElement
                    inputName="DNI *"
                    inputValue={this.state.dni}
                    onChange={(event) => this.handleUserInput(event)}
                    error={this.state.dniError}
                    elementId="dni"
                    isPassword={false}
                    disabled={!this.props.isNewClient}
                />
                <GenericFormElement
                    inputName="Teléfono *"
                    inputValue={this.state.phone}
                    onChange={(event) => this.handleUserInput(event)}
                    error={this.state.phoneError}
                    elementId="phone"
                    isPassword={false}
                />
                { this.state.successMessage &&
                    <p className="success-message">{this.state.successMessage}</p>
                }
                { this.state.errorMessage &&
                    <p className="error-message">{this.state.errorMessage}</p>
                }
                <GenericFormSubmitButton
                    buttonName={this.props.buttonName}
                    buttonColorClasses="orange"
                    isButtonDisabled={this.isButtonDisabled()}
                />
            </form>
        </>
    );
    }
}

export class DeleteClientModalBody extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            successMessage: "",
        };
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    deleteClient(event) {
        let data = {
            "user_id": this.props.item_to_delete.user_id
        };

        deleteClientAsAdmin(
            data,
            (value, callback) => {
                this.setState(value, callback);
            },
            this.props.updateParentState,
            this.props.history,
            this.deleteClient
        )

        if (event) {
            event.preventDefault();
        }
    }

    render() {
        return (
        <>
            <form className="adminDeleteClientForm" onSubmit={(event) => this.deleteClient(event)}>
                <p className="text-center">
                    ¿Está seguro de que desea dar de baja a {this.props.item_to_delete.full_name} como cliente?
                </p>
                { this.state.successMessage &&
                    <p className="success-message">{this.state.successMessage}</p>
                }
                { this.state.errorMessage &&
                    <p className="error-message">{this.state.errorMessage}</p>
                }
                <GenericFormSubmitButton
                    buttonName="Dar de baja"
                    buttonColorClasses="blue darken-4"
                    isButtonDisabled={false}
                />
            </form>
        </>
    );
    }
}

class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            should_force_update: Date.now()
        }
    }

    componentDidMount() {
        const options = {
            duration: 300,
            onShow: null,
            swipeable: false,
            responsiveThreshold: Infinity
        };
        var elem = document.querySelector('.tabs');
        M.Tabs.init(elem, options);
        this.history = createBrowserHistory();
    }

    handleAdminLogoutEvent(event) {
        clearLocalStorage(this.props.updateParentState, this.history);
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    render() {
        return (
            <div className="admin">
                <img
                    src={logo}
                    className="mainLogo"
                    alt="Reserva Tajamar"
                />
                <div className="row">
                    <div className="col s12">
                        <ul className="tabs">
                            <li className="tab col s3 client-tab-link"><a className="active" href="#clients-tab">Clientes</a></li>
                            <li className="tab col s3 points-tab-link"><a href="#points-tab">Puntos</a></li>
                        </ul>
                    </div>
                    <div id="clients-tab" className="admin-content col s12">
                        <AdminClients key={this.state.should_force_update} history={this.history} updateParentState={this.updateState} />
                    </div>
                    <div id="points-tab" className="admin-content col s12">
                        <AdminPoints history={this.history} updateParentState={this.updateState} />
                    </div>
                </div>
                <div className="admin-logout-footer" onClick={(event) => this.handleAdminLogoutEvent(event)}>
                    Cerrar sesión
                    <div className="admin-icon-background">
                        <Icon className="menu-icon">directions_run</Icon>
                    </div>
                </div>
            </div>
        );
    }
}

class AdminClients extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            'client_data': [],
            'isLoading': false
        };
    }

    componentDidMount() {
        getAdminClients(
            (value, callback) => {
                this.setState(value, callback);
            },
            this.props.updateParentState,
            this.props.history
        );
    }

    render(){
        // Sometimes the modal doesn't reset the body visibility and then you can't scroll anymore, therefore the next line.
        document.body.style.overflow = "visible";
        return (
            <div className="admin-clients">
                <Spinner isLoading={this.state.isLoading}/>
                <div className="admin-actions">
                    <div className="news-container">
                        <Button
                            className="btn-flat modal-trigger"
                            data-target={"AdminAddNewsModalID"}
                        >
                        {
                            <div className="add-news">
                                Añadir novedad
                                <div className="admin-icon-background">
                                    <Icon className="menu-icon">chat</Icon>
                                </div>
                            </div>
                        }
                        </Button>
                        <GenericModal
                            cardKey={ "admin-new-client-modal-key" }
                            modalTitle={ "Alta de Novedades" }
                            modalBody={
                                <AddNewsModalBody
                                    updateParentState={this.props.updateParentState}
                                    buttonName={"Dar de alta"}
                                />
                            }
                            modalId={"AdminAddNewsModalID" }
                            modalHref={"AdminAddNewsModalID"}
                        />
                    </div>
                    <div className="new-client-container">
                        <Button
                            className="btn-flat modal-trigger"
                            data-target={"AdminAddNewClientModalID"}
                        >
                        {
                            <div className="new-client">
                                Añadir nuevo cliente
                                <div className="admin-icon-background">
                                    <Icon className="menu-icon">person_add</Icon>
                                </div>
                            </div>
                        }
                        </Button>
                        <GenericModal
                            cardKey={ "admin-new-client-modal-key" }
                            modalTitle={ "Alta de Clientes" }
                            modalBody={
                                <AddNewClientModalBody
                                    updateParentState={this.props.updateParentState}
                                    buttonName={"Dar de alta"}
                                    item={this.state.item}
                                    isNewClient={true}
                                />
                            }
                            modalId={"AdminAddNewClientModalID" }
                            modalHref={"AdminAddNewClientModalID"}
                        />
                    </div>
                </div>
                <div className="client-list">
                    <h1>Lista de Clientes</h1>
                        <AdminClientsTable
                            data={this.state.client_data}
                            updateParentState={this.props.updateParentState}
                            history={this.props.history}
                        />
                </div>
            </div>
        );
    }
}

export class AdminClientsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                'user_id': "",
                'full_name': "",
                'email': "",
                'phone': "",
                'dni': ""
            },
            triggerType: ''
        };
    }

    handleModificationButtonClick(event, item, triggerType) {
        this.setState({
            item: item,
            triggerType: triggerType
        });
        event.preventDefault();
    }

    render(){
        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <th>
                                Nombre y Apellido
                            </th>
                            <th>
                                DNI
                            </th>
                            <th>
                                E-mail
                            </th>
                            <th>
                                Teléfono
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            {this.props.data.map((item,index)=>{
                                return (
                                    <tr>
                                        <td>
                                            {item.full_name}
                                        </td>
                                        <td>
                                            {item.dni}
                                        </td>
                                        <td>
                                            {item.email}
                                        </td>
                                        <td>
                                            {item.phone}
                                        </td>
                                        <td className="modification-column">
                                            <div className="modify-client-container">
                                                <Button
                                                    className="btn-flat modal-trigger"
                                                    data-target={"AdminModifyClientModalID"}
                                                    onClick={ (event) => this.handleModificationButtonClick(event, item, "modification")}
                                                >
                                                    {
                                                        <div className="admin-icon-background">
                                                            <Icon className="admin-edit-icon">border_color</Icon>
                                                        </div>
                                                    }
                                                </Button>
                                            </div>
                                            <div className="delete-client-container">
                                                <Button
                                                    className="btn-flat modal-trigger"
                                                    data-target={"AdminDeleteClientModalID"}
                                                    onClick={ (event) => this.handleModificationButtonClick(event, item, "delete")}
                                                >
                                                    {
                                                        <div className="delete-client">
                                                            <div className="admin-icon-background">
                                                                <Icon className="admin-delete-icon">delete</Icon>
                                                            </div>
                                                        </div>

                                                }
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                </Table>
                <div className="delete-client-container">
                    <GenericModal
                        cardKey={ "admin-delete-client-modal-key" }
                        modalTitle={ "Baja de Clientes" }
                        modalBody={
                            <DeleteClientModalBody
                                item_to_delete={this.state.item}
                                updateParentState={this.props.updateParentState}
                            />
                        }
                        modalId={"AdminDeleteClientModalID"}
                        modalHref={"AdminDeleteClientModalID"}
                    />
                </div>
                <div className="delete-client-container">
                    <GenericModal
                        cardKey={ "admin-modify-client-modal-key" }
                        modalTitle={ "Modificación de Clientes" }
                        modalBody={
                            <AddNewClientModalBody
                                key={Date.now()}
                                updateParentState={this.props.updateParentState}
                                buttonName={"Modificar"}
                                item={this.state.item}
                                isNewClient={false}
                            />
                        }
                        modalId={"AdminModifyClientModalID" }
                        modalHref={"AdminModifyClientModalID"}
                    />
                </div>
            </>
        );
    }
}

class AdminPoints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'file': '',
            'errorMessage': '',
            'successMessage': '',
            'isLoading': false
        }
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    handleCSVUploadEvent(event) {
        uploadFileAsAdmin(
            this.state.file,
            this.updateState,
            this.props.updateParentState,
            this.props.history,
            this.handleCSVUploadEvent
        );

        event.preventDefault();
    }

    handleCSVInput = async (event) => {
        const file = event.target.files[0];

        let errorMessage = validateAdminCSVFilename(file.name);

        this.setState({
            errorMessage: errorMessage,
            file: file
        });
    }

    isButtonDisabled = () => {
        return ((this.state.file !== "" && this.state.errorMessage !== "")
            || this.state.file === "");
    }

    render(){
        return (
            <div className="adminPointsContainer">
                <Spinner isLoading={this.state.isLoading}/>
                <p id="points-tab-instruction">
                    Elija el archivo de extensión CSV que contiene la información sobre puntos
                </p>
                <form className="adminPointsForm" onSubmit={(event) => this.handleCSVUploadEvent(event) }>
                    <div className="file-upload-container">
                        <GenericFormElement
                            inputName=""
                            inputValue={this.state.points_csv_file}
                            onChange={(event) => this.handleCSVInput(event)}
                            error={null}
                            elementId="pointsfile"
                            isPassword={false}
                            type="file"
                            label=""
                        />
                        <GenericFormSubmitButton
                            buttonName="Importar"
                            buttonColorClasses="blue darken-4"
                            isButtonDisabled={this.isButtonDisabled()}
                        />
                    </div>
                    { this.state.errorMessage &&
                        <p className="error-message">{this.state.errorMessage}</p>
                    }
                    { this.state.successMessage &&
                        <p className="success-message">{this.state.successMessage}</p>
                    }
                </form>
            </div>
        );
    }
}

export default Admin;
