export function validateNameSurname(value, errorSetter) {
    if (value.length < 2) {
        errorSetter({
            "namesurnameError": "El nombre ingresado es muy corto"
        });
    }
    else if (!value.includes(" ")) {
        errorSetter({
            "namesurnameError": "Por favor ingrese el nombre y apellido"
        });
    }
    else {
        errorSetter({
            "namesurnameError": ""
        });
    }
}

export function validatePhone(value, errorSetter) {
    let isPhoneValid = String(value)
        .toLowerCase()
        .match(
            /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g
        );
    if (!isPhoneValid) {
        errorSetter({
            "phoneError": "El número de teléfono ingresado no es válido"
        });
    }
    else {
        errorSetter({
            "phoneError": ""
        });
    }
}

export function validateOptionalField(value, errorSetter) {
}