import React from 'react';

import { GenericFormSubmitButton, GenericFormElement, GenericLinkElement } from '../components/Generic';
import { validatePassword } from '../validators/Login';
import { validateDNI } from '../validators/Admin'
import { logInUser } from '../apis/endpoints';
import { Spinner } from '../components/Spinner';
import ForgotPasswordForm from './ForgotPassword';

import logo from '../assests/logo.png';

import '../styles/Login.css';


let validatorsMapping = {
    "dni": validateDNI,
    "password": validatePassword
};

class LoginManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldShowForgotPassword: false,
        }
    }

    updateState = (data) => {
        this.setState(data);
    }

    render() {
        return (
        <>
            {
                this.state.shouldShowForgotPassword
                ?
                <ForgotPasswordForm
                    updateState={this.props.updateState}
                    setisUserAdmin={this.props.setisUserAdmin}
                    setState={this.props.setState}
                    updateLoginManagerState={this.updateState}
                />
                :
                <Login
                    updateState={this.props.updateState}
                    setisUserAdmin={this.props.setisUserAdmin}
                    setState={this.props.setState}
                    updateLoginManagerState={this.updateState}
                />
            }
        </>
        );
    }
}


export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isUserLoggedin: false,
            dni: '',
            dniError: '',
            password: '',
            passwordError: '',
        }
    }

    isButtonDisabled() {
        return (this.state.dni === '' || this.state.password ==='')
            || (this.state.dni !== ''
                && this.state.dniError !== '')
            || (this.state.password !== ''
                && this.state.passwordError !== '');
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    handleUserInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
        validatorsMapping[name](
            value,
            this.updateState
        );
    }

    handleLogin(event) {
        logInUser(
            this.state.dni,
            this.state.password,
            (data, callback) => {
                this.setState(data, callback);
            },
            this.props.updateState
        );
        event.preventDefault();
    }

    handleForgotPassword(event) {
        this.props.updateLoginManagerState({
            shouldShowForgotPassword: true
        });
        event.preventDefault();
    }

    render() {
        return (
            <div className="login">
                <Spinner isLoading={this.state.isLoading}/>
                <form className="loginForm" onSubmit={this.handleLogin.bind(this)}>
                    <img
                        src={logo}
                        className="mainLogo"
                        alt="Reserva Tajamar"
                    />
                    <GenericFormElement
                        inputName="DNI"
                        inputValue={this.state.dni}
                        elementId="dni"
                        onChange={(event) => this.handleUserInput(event)}
                        error={this.state.dniError}
                        isPassword={false}
                    />
                    <GenericFormElement
                        inputName="Contraseña"
                        inputValue={this.state.password}
                        elementId="password"
                        onChange={(event) => this.handleUserInput(event)}
                        error={this.state.passwordError}
                        isPassword={true}
                    />
                    { this.state.errorMessage &&
                        <p className="error-message">{this.state.errorMessage}</p>
                    }
                    <GenericLinkElement
                        buttonName="Olvidé mi contraseña"
                        onClick={ (event) => this.handleForgotPassword(event) }
                    />
                    <GenericFormSubmitButton
                        buttonName="Iniciar Sesión"
                        buttonColorClasses="blue darken-4"
                        isButtonDisabled={this.isButtonDisabled()}
                    />
                </form>
            </div>
        );
    }
}

export default LoginManager;
