import React from 'react';

import '../styles/Generic.css';


import {
    Button,
    TextInput,
    Row,
    Col,
    Card,
    Modal,
    Textarea
} from 'react-materialize';

import M from 'materialize-css';


export class GenericModal extends React.Component {

    componentDidMount() {
        M.AutoInit();
        const options = {
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            startingTop: "4%",
            endingTop: "10%"
        };
        M.Modal.init(this.Modal, options);
    }

    render() {
        return (
        <>
            <div
                ref={Modal => {
                    this.Modal = Modal;
                }}
                href={this.props.modalHref}
                id={this.props.modalId}
                className="modal"
            >
                <div className="modal-content">
                    <h4 className="text-center">{ this.props.modalTitle }</h4>
                    { this.props.modalBody }
                </div>
                <div className="modal-footer">
                    <a href="#" className="modal-close waves-effect btn orange darken-1">
                    Cerrar
                    </a>
                </div>
            </div>
        </>
        );
    }
}

export function GenericFormElement(props) {
    return (
        <div className="genericFormElement">
            { props.inputName &&
            <div className="genericFormLabel">
                { props.inputName }
            </div>
            }
            <p className="generic-error-message">
                { props.error }
            </p>
            <TextInput
                className="browser-default genericFormInput"
                id={props.elementId}
                value={props.inputValue}
                onChange={props.onChange}
                name={props.elementId}
                password={props.isPassword}
                type={props.type}
                label={props.label}
                disabled={props.disabled}
            />
        </div>
    );
}

export function GenericBigFormElement(props) {
    return (
        <div>
            <div className="genericFormElement">
                <div className="genericFormLabel">
                    { props.inputName }
                </div>
            </div>
            <div>
                <Row>
                    <Textarea
                        className="genericFormInput genericBigFormInput browser-default"
                        l={12}
                        m={12}
                        s={12}
                        xl={12}
                        id={props.elementId}
                        value={props.inputValue}
                        onChange={props.onChange}
                        name={props.elementId}
                        type={props.type}
                        label={props.label}
                    />
                </Row>
            </div>
        </div>
    );
}

export function GenericLinkElement(props) {
    return (
        <div className="genericLinkElement">
            <span className="genericLink" onClick={props.onClick}>
                { props.buttonName }
            </span>
        </div>
    );
}

export function GenericFormSubmitButton(props) {
    let classes = `waves-effect btn ${props.buttonColorClasses}`;

    return (
        <Button
            node="button"
            className={classes}
            data-target={props.dataTarget}
            key={"button-" + props.cardKey}
            disabled={props.isButtonDisabled}
        >
            { props.buttonName }
        </Button>
    );
}

export function GenericErrorMessage(props) {
    return (
        <p className="generic-error-message">
            { props.error }
        </p>
    );
}

export function GenericCard(props) {
    return (
        <Row key={props.modalLocation + "-row-" + props.cardKey}>
            <Col
                m={12}
                s={12}
                key={props.modalLocation + "-col-" + props.cardKey}
            >
                <Card
                    className="generic-card"
                    textClassName="black-text text-center"
                    title={ props.cardTitle }
                >
                    <GenericFormSubmitButton
                        dataTarget={ props.modalHref }
                        buttonName="Más información"
                        buttonColorClasses="orange darken-1 modal-trigger"
                        key={ "GenericFormSubmitButton-" + props.cardKey }
                    />
                </Card>
                <GenericModal
                    cardKey={ props.modalLocation + "-" + props.cardKey }
                    modalTitle={ props.cardTitle }
                    modalBody={ props.modalBody }
                    modalId={ props.modalHref }
                    modalHref={ props.modalHref }
                />
            </Col>
        </Row>
    );
}

export function Line(props) {
    return (
        <hr
            style={{
                color: '#EB8B3D',
                backgroundColor: '#EB8B3D',
                height: 3,
                width: props.width
            }}
        />
    );
}