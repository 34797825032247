import React from 'react';
import { validateEmail } from '../validators/Login';
import { validateNameSurname, validatePhone, validateOptionalField } from '../validators/Contact';
import { sendContactData } from '../apis/endpoints';
import { GenericFormSubmitButton, GenericFormElement, GenericBigFormElement } from '../components/Generic';
import { Spinner } from '../components/Spinner';

import '../styles/Contacto.css';
import '../styles/Generic.css';

import whatsapp from '../assests/whatsapp.png';

let CANTER_PHONE_NUMBER = "5493512186509";

let validatorsMapping = {
    "namesurname": validateNameSurname,
    "email": validateEmail,
    "phone": validatePhone,
    "subject": validateOptionalField,
    "message": validateOptionalField,
}


class Contacto extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            errorMessage: '',
            successMessage: '',
            namesurname: '',
            namesurnameError: '',
            email: '',
            emailError: '',
            phone: '',
            phoneError: '',
            subject: '',
            message: '',
        };
    }

    isButtonDisabled() {
        return this.state.namesurname === ''
            || this.state.email === ''
            || this.state.phone === '' || (
                this.state.namesurname !== '' && this.state.namesurnameError !== ''
            )
            || (
                this.state.email !== '' && this.state.emailError !== ''
            )
            || (
                this.state.phone !== '' && this.state.phoneError !== ''
            );
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    handleUserInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value,
        });
        validatorsMapping[name](value, this.updateState);
    }

    handleContact = (event) => {
        let contact_data = {
            'full_name': this.state.namesurname,
            'email': this.state.email,
            'phone': this.state.phone,
            'subject': this.state.subject,
            'message': this.state.message
        }
        sendContactData(
            contact_data,
            this.updateState,
            this.props.updateParentState,
            this.props.history,
            this.handleContact
        );

        if (event) {
            event.preventDefault();
        }
    }

    render() {
        document.body.style.overflow = "visible";
        return (
            <div className="generic-logged-in-page">
                <div className="contacto-cuerpo">
                    <Spinner isLoading={this.state.isLoading}/>
                    { !this.state.successMessage ?
                        <form className="contactForm" onSubmit={(event) => this.handleContact(event)}>
                        <GenericFormElement
                            inputName="Nombre y apellido *"
                            inputValue={this.state.namesurname}
                            onChange={(event) => this.handleUserInput(event)}
                            error={this.state.namesurnameError}
                            elementId="namesurname"
                            isPassword={false}
                        />
                        <GenericFormElement
                            inputName="E-mail *"
                            inputValue={this.state.email}
                            onChange={(event) => this.handleUserInput(event)}
                            error={this.state.emailError}
                            elementId="email"
                            isPassword={false}
                        />
                        <GenericFormElement
                            inputName="Teléfono *"
                            inputValue={this.state.phone}
                            onChange={(event) => this.handleUserInput(event)}
                            error={this.state.phoneError}
                            elementId="phone"
                            isPassword={false}
                        />
                        <GenericFormElement
                            inputName="Asunto"
                            inputValue={this.state.subject}
                            onChange={(event) => this.handleUserInput(event)}
                            error={null}
                            elementId="subject"
                            isPassword={false}
                        />
                        <GenericBigFormElement
                            inputName="Mensaje"
                            inputValue={this.state.message}
                            onChange={(event) => this.handleUserInput(event)}
                            error={null}
                            elementId="message"
                            isPassword={false}
                        />
                        <GenericFormSubmitButton
                            buttonName="Enviar"
                            buttonColorClasses="orange darken-1 contact-button"
                            isButtonDisabled={this.isButtonDisabled()}
                        />
                        { this.state.errorMessage &&
                            <p className="error-message">{this.state.errorMessage}</p>
                        }
                        </form>
                    :
                        <></>
                    }
                    { this.state.successMessage &&
                        <p className="success-message">{this.state.successMessage}</p>
                    }
                </div>
                <a
                    href={`https://wa.me/${CANTER_PHONE_NUMBER}`}
                    target="_blank"
                    rel={'external noreferrer noopener'}
                >
                    <img
                        src={whatsapp}
                        className="whatsapp"
                        alt="Reserva Tajamar Whatsapp"
                    />
                </a>
            </div>
        );
    }
}

export default Contacto;
