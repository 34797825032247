import React from 'react';
import { sendChangePasswordRequest } from '../apis/endpoints';
import { GenericFormSubmitButton, GenericFormElement } from '../components/Generic';
import { Spinner } from '../components/Spinner';
import { validatePassword } from '../validators/Login';
import { validateNewPassword } from '../validators/Clave';

import '../styles/Clave.css';
import '../styles/Generic.css';

let validatorsMapping = {
    "oldpassword": validatePassword,
    "newpasswordone": validateNewPassword,
    "newpasswordtwo": validateNewPassword,
}

let errorsMapping = {
    "oldpassword": "oldpasswordError",
    "newpasswordone": "newpasswordoneError",
    "newpasswordtwo": "newpasswordoneError",
}

let statusMapping = {
    "oldpassword": "oldpassword",
    "newpasswordone": "newpasswordtwo",
    "newpasswordtwo": "newpasswordone",
}


class Clave extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            errorMessage: '',
            successMessage: '',
            oldpassword: '',
            oldpasswordError: '',
            newpasswordone: '',
            newpasswordoneError: '',
            newpasswordtwo: '',
        };
    }

    isButtonDisabled() {
        return (this.state.oldpasswordError !== ''
            || this.state.onewpasswordoneError !== ''
            || this.state.onewpasswordtwoError !== ''
            )
            && 
            (
            this.state.oldpassword === ''
            || this.state.newpasswordone === ''
            || this.state.newpasswordtwo === ''
            );
    }

    updateState = (data, callback=null) => {
        this.setState(data, callback);
    }

    handleUserInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value,
        });
        validatorsMapping[name](
            value,
            this.updateState,
            this.state[statusMapping[name]],
            errorsMapping[name]
        );
    }

    handlePasswordChange = (event) => {
        let account_data = {
            'old_password': this.state.oldpassword,
            'new_password': this.state.newpasswordone
        }
        sendChangePasswordRequest(
            account_data,
            this.updateState,
            this.props.updateParentState,
            this.props.history,
            this.handlePasswordChange
        );

        if (event) {
            event.preventDefault();
        }
    }

    render() {
        document.body.style.overflow = "visible";
        return (
            <div className="generic-logged-in-page">
                <div className="cambio-clave-titulo">
                    <h1>Cambio de contraseña</h1>
                </div>
                <div className="cambio-clave-cuerpo">
                    <Spinner isLoading={this.state.isLoading}/>
                    { !this.state.successMessage ?
                        <form className="changePasswordForm" onSubmit={(event) => this.handlePasswordChange(event)}>
                        <GenericFormElement
                            inputName="Contraseña actual"
                            inputValue={this.state.oldpassword}
                            onChange={(event) => this.handleUserInput(event)}
                            error={this.state.oldpasswordError}
                            elementId="oldpassword"
                            isPassword={true}
                        />
                        <GenericFormElement
                            inputName="Nueva contraseña"
                            inputValue={this.state.newpasswordone}
                            onChange={(event) => this.handleUserInput(event)}
                            error={this.state.newpasswordoneError}
                            elementId="newpasswordone"
                            isPassword={true}
                        />
                        <GenericFormElement
                            inputName="Repetí la nueva contraseña"
                            inputValue={this.state.newpasswordtwo}
                            onChange={(event) => this.handleUserInput(event)}
                            error={this.state.newpasswordtwoError}
                            elementId="newpasswordtwo"
                            isPassword={true}
                        />
                        <GenericFormSubmitButton
                            buttonName="Guardar"
                            buttonColorClasses="orange darken-1 cambio-clave-button"
                            isButtonDisabled={this.isButtonDisabled()}
                        />
                        { this.state.errorMessage &&
                            <p className="error-message">{this.state.errorMessage}</p>
                        }
                        </form>
                    :
                        <></>
                    }
                    { this.state.successMessage &&
                        <p className="success-message">{this.state.successMessage}</p>
                    }
                </div>
            </div>
        );
    }
}

export default Clave;
